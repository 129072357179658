/// <reference path="app.js" />
angular
    .module('app')
    .config(config);

function config($stateProvider, $urlRouterProvider, $ocLazyLoadProvider, $locationProvider) {
    $urlRouterProvider.otherwise("/auth/login");
    $locationProvider.html5Mode(true);
    $stateProvider
        .state('auth',
        {
            url: "/auth",
            abstract: true, // indentify template
            controller: 'appController as app',
            templateUrl: "app/components/layouts/login.html"
        })
        .state('auth.login',
        {
            url: "/login",
            controller: 'loginController as login',
            templateUrl: "app/auth/login/index.html",
            data: {
                allowGuest: true
            }
        })
        .state('auth.logout',
        {
            url: "/logout",
            controller: 'LogoutController as logout',
            template: "waitting",
            data: {
                allowGuest: true
            }
        })
        .state('auth.forgot',
        {
            url: "/forgot",
            controller: 'ForgotController as forgot',
            templateUrl: "app/auth/forgot/forgot.html",
            data: {
                allowGuest: true
            }
        })
        .state('auth.register',
        {
            url: "/register",
            controller: 'registerController as register',
            templateUrl: "app/auth/register/index.html"
        })
        .state('auth.resetpass',
        {
            url: "/resetpass",
            controller: 'resetpassController as resetpass',
            templateUrl: "app/auth/resetpass/index.html",
            params: { message: null, username: null },
            data: {
                allowGuest: true
            }
        })
        .state('auth.hello',
        {
            url: "/hello",
            controller: 'helloController as hello',
            templateUrl: "app/auth/hello/index.html",
            data: {
                allowGuest: true
            }
        })
        .state('users',
        {
            url: "/users",
            abstract: true, //indentify template
            controller: 'appController as app',
            templateUrl: "app/components/layouts/master.html",
            data: {
                allowGuest: false
            }
        })
         .state('dashboards',
        {
            url: "/dashboards",
            //abstract: true, //indentify template
            controller: 'dashboardController as dashboard',
            templateUrl: "app/dashboard/template/template.html",
            data: {
                allowGuest: false
            },
            permissions: ["administration"]
		})
		.state('terms',
		{
			url: "/terms",
			abstract: true, //indentify template
			controller: 'appController as app',
			templateUrl: "app/components/layouts/master.html"
		})
		.state('terms.termsoflicense',
		{
			url: "/termsoflicense",
			controller: 'termsController as terms',
			templateUrl: "app/dashboard/terms/termsoflicense.html",
			data: {
				allowGuest: true
			}
		})
         .state('settings',
        {
            url: "/settings",
            abstract: true, //indentify template
            controller: 'appController as app',
            templateUrl: "app/components/layouts/master.html"
        })
          .state('settings.roles',
        {
            url: "/roles",
            controller: 'roleController as role',
            templateUrl: "app/dashboard/role/index.html",
            data: {
                allowGuest: false
            }
		})
        .state('settings.users',
        {
            url: "/users",
            controller: 'userManagementController as userManagement',
            templateUrl: "app/dashboard/users/management/index.html",
            data: {
                allowGuest: false
            }
        })

        .state('settings.edit_profile',
        {
            url: "/edit-profile",
            controller: 'userEditController as userEditCtrl',
            templateUrl: "app/dashboard/users/edit/edit.html",
            data: {
                allowGuest: false
            }
        })
         .state('settings.organisations',
        {
            url: "/organisations",
            controller: 'organisationController as organisation',
            templateUrl: "app/dashboard/organisation/index.html",
            data: {
                allowGuest: false
            }

        })
         .state('settings.languages',
        {
            url: "/languages",
            controller: 'languageController as language',
            templateUrl: "app/dashboard/languages/index.html",
            data: {
                allowGuest: false
            }

        })
         .state('settings.translate',
        {
            url: "/translate",
            controller: 'translateController as translate',
            templateUrl: "app/dashboard/languages/translate/index.html",
            data: {
                allowGuest: false
            }

        })

        .state('settings.countries',
        {
            url: "/countries",
            controller: 'countryController as country',
            templateUrl: "app/dashboard/country/index.html",
            data: {
                allowGuest: false
            }

        })
         .state('settings.products',
        {
            url: "/products",
            controller: 'productController as product',
            templateUrl: "app/dashboard/system/product/index.html",
            data: {
                allowGuest: false
            }
        })
        .state('settings.productfilter', {
            url: "/productfilters",
            controller: 'productFilterManagementController as productFilterManagement',
            templateUrl: "app/dashboard/productfilter/index.html",
            data: {
                allowGuest: false
            }
        })
        .state('settings.newproductfilter', {
            url: "/newproductfilter",
            controller: 'productFilterAddNewController as productFilterAddNew',
            templateUrl: "app/dashboard/productfilter/addnew/index.html",
            data: {
                allowGuest: false
            }
        })
        .state('settings.documents',
        {
            url: '/documents',
            controller: 'blobDocumentController as blobDocument',
            templateUrl: 'app/dashboard/management_documents/view/index.html',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['Content/plugins/uiTree/angular-ui-tree.min.css', 'scripts/plugins/uiTree/angular-ui-tree.min.js']
                        }
                    ]);
                }
            },
            data: { allowGuest: false }
        })

        .state('projects',
        {
            url: "/projects",
           // abstract: true, //indentify template
          controller: 'projectListController as projectList',
          templateUrl: "app/dashboard/project/template/template.html",
          data: {
              allowGuest: false
          }
        })
       .state('projects.detail',
        {
           // url: "/detail/{projectName}/{projectId}",
            url: "/detail/{projectName}/{projectId}/{reference}",
            controller: 'projectDetailController as projectDetail',
            templateUrl: "app/dashboard/project/projectDetail/index.html",
            params: {
                projectItem: null,
                projectId: {
                    value: null,
                    squash: true
                },
                projectName: {
                    value: null,
                    squash: true
                },
                reference: {
                    value: null,
                    squash: true
                },
            },
            data: {
                allowGuest: false
            },
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['Content/plugins/steps/jquery.steps.css', 'scripts/plugins/iCheck/icheck.min.js']
                        },
                         {
                             files: ['Content/plugins/slick/slick.css', 'Content/plugins/slick/slick-theme.css', 'scripts/plugins/slick/slick.min.js']
                         }
                         ,
                        {
                            name: 'slick',
                            files: ['scripts/plugins/slick/angular-slick.min.js']
                        }
                    ]);
                }
            }
        })

         .state('projects.system',
        {
            url: "/system",
            controller: 'systemController as system',
            templateUrl: "app/dashboard/system/index.html",
            params: { systemObject: null, projectItem: null },
            data: {
                allowGuest: false
            }
        })
         .state('projects.project_documents',
        {
            url: "/document_photo",
            controller: 'project_documentController as project_document',
            templateUrl: "app/dashboard/document_photo/index.html",
            params: { projectItem: null },
            data: {
                allowGuest: false
            }
        })
           .state('projects.notes',
        {
            url: "/notes",
            controller: 'noteController as note',
            templateUrl: "app/dashboard/note/index.html",
            params: { projectItem: null },
            data: {
                allowGuest: false
            }
        })
         .state('projects.reports',
        {
            url: "/reports",
            controller: 'reportController as report',
            templateUrl: "app/dashboard/report/index.html",
            params: { projectItem: null },
            data: {
                allowGuest: false
            }
        })
        .state('projects.generateReports',
        {
            //url: "/generate_reports",
            url: "/generate_reports/{reportId}/{reference}",
            controller: 'generateReportController as generateReport',
            templateUrl: "app/dashboard/report/generate_report/index.html",
            //params: { projectItem: null, reportItem: null, isNew: null },
            params: {
                projectItem: null,
                reportItem: {
                    value: null,
                    squash: true
                },
                reportName: {
                    value: null,
                    squash: true
                },
                reportId: {
                    value: null,
                    squash: true
                },
                reference: {
                    value: null,
                    squash: true
                },
                isNew: {
                    value: null,
                    squash: true
                }
            },
            data: {
                allowGuest: false
            }
        })

        .state('projects.revisions',
        {
            url: "/revisions",
            controller: 'revisionController as revision',
            templateUrl: "app/dashboard/revision/index.html",
            params: { projectItem: null },
            data: {
                allowGuest: false
            }
        })
         .state('projects.reviewsystem',
        {
            url: "/reviewsystem",
            controller: 'reviewSystemController as reviewSystem',
            templateUrl: "app/dashboard/system/reviewsystem/index.html",
            params: { systemObject: null, projectItem: null },
            data: {
                allowGuest: false
            },
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['Content/plugins/steps/jquery.steps.css', 'scripts/plugins/iCheck/icheck.min.js']
                        },
                         {
                             files: ['Content/plugins/slick/slick.css', 'Content/plugins/slick/slick-theme.css', 'scripts/plugins/slick/slick.min.js']
                         }
                         ,
                        {
                            name: 'slick',
                            files: ['scripts/plugins/slick/angular-slick.min.js']
                        }
                    ]);
                }
            }
        })
        .state('reportnew',
        {
            url: "/reportnew",
            abstract: true, //indentify template
            controller: 'appController as app',
            templateUrl: "app/components/layouts/master.html",
        })

        .state('reportnew.index',
        {
            url: "/generate_report_new",
            controller: 'reportTemplateController as reportTemplate',
            data: {
                allowGuest: false
            },
            templateUrl: "app/dashboard/generate_report_new/index.html",
        });
    ;

}