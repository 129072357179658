angular
    .module('app')
    .config(translateConfig)
    .config(['$httpProvider',function($httpProvider) {
        $httpProvider.interceptors.push('httpInterceptor');
    }])
    .run(function ($rootScope) {
        $rootScope.typeOf = function (value) {
            return typeof value;
        };
    })
    .directive('stringToNumber', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (value) {
                    return '' + value;
                });
                ngModel.$formatters.push(function (value) {
                    return parseFloat(value);
                });
            }
        };
    })
    .factory('loadLanguage', loadLanguage)
    .factory('uploadDocument', uploadDocument)
    .factory('loadLanguagesJson', loadLanguagesJson)
    .factory('httpInterceptor', ['$q', '$location',function ($q, $location) {
        var canceller = $q.defer();
        return {
            'request': function(config) {
                // promise that should abort the request when resolved.
                config.timeout = canceller.promise;
                return config;
            },
            'response': function(response) {
                return response;
            },
            'responseError': function(rejection) {
                if (rejection.status === 403) {
                    canceller.resolve('Forbidden');
                    window.location.href = "/auth/login";   
                }
                return $q.reject(rejection);
            }

        };
    }
    ]);
    //Http Intercpetor to check auth failures for xhr requests
   
var languageCode = "en-US";
var recentItems= 5;
function translateConfig($translateProvider) {
    // get  translation file for mutil languages
    $translateProvider.useLoader('loadLanguagesJson', {});
    
    $translateProvider.preferredLanguage(languageCode);
    $translateProvider.useSanitizeValueStrategy(null);
    //load trace log
    $translateProvider.useMissingTranslationHandlerLog();
}
//function: get language from  json file
function loadLanguage($http, $q) {
    return function (options) {
        var deferred = $q.defer();
        console.log('options.key:' + options.key);
        $http({
			method: 'GET',
			cache: false,
            url: 'i18n/' + options.key + '.json'
        }).then(function (data) {
            deferred.resolve(data);
            console.log(data);
        },function () {
            deferred.reject(options.key);
        });
        return deferred.promise;
    };
}

function loadLanguagesJson($http, $q, $filter, $cookies) {

    return function (options) {
		var deferred = $q.defer();
		var flag = ($cookies.get('cachingFlag') != undefined) ? true : false;
        console.log('options.key:' + options.key);
        $http({
			method: 'GET',
			headers: flag ? { 'Cache-Control': 'no-cache' } : {},
            url: apiUrl+"language/GetTranslateData"
		}).then(function (response) {
			$cookies.remove('cachingFlag');
            var dataObject = $filter('filter')(response.data, { code: options.key });
            if (dataObject.length >0) deferred.resolve(dataObject[0].data);
        },function () {
            deferred.reject(options.key);
        });
        return deferred.promise;
    };
}
function uploadDocument($http) {
    return {
        getSASToken: function (extension) {
            return $http.get(apiUrl+'/Document/' + extension);
        },
        postItem: function (item) {
            return $http.post(apiUrl+'/Document/', item);
        }
    };
}




