"use strict";

angular
    .module("app")
    .service("AppHttp", AppHttp)
    .service("AppCookies", AppCookies)
    .service("AppStorage", AppStorage)
    .service("AppDBStorage", AppDBStorage)
    .service("AppToast", AppToast)
    .service("AppDialog", AppDialog)
    .service("AppLoading", AppLoading)
    .service("AppDatePicker", AppDatePicker)
    .service("AppHelper", AppHelper)
    .service("WizardData", WizardData)
    .service("Common", Common)
    .service("Authorization", Authorization)
    .filter("range", function () {
        return function (input, min, max) {
            min = parseInt(min); //Make string input int
            max = parseInt(max);
            for (var i = min; i < max; i++)
                input.push(i);
            return input;
        };
    })
.directive("repeatEnd", function () {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            if (scope.$last) {
                scope.$eval(attrs.repeatEnd);
            }
        }
    };
});
angular.module("ngTouch", [])
.directive("ngTouchstart", function () {
    return {
        controller: ["$scope", "$element", function ($scope, $element) {

            $element.bind("touchstart", onTouchStart);
            function onTouchStart(event) {
                var method = $element.attr("ng-touchstart");
                $scope.$event = event;
                $scope.$apply(method);
            }

        }]
    };
    })
.directive("ngTouchmove", function () {
    return {
        controller: ["$scope", "$element", function ($scope, $element) {

            $element.bind("touchmove", onTouchMove);

            function onTouchMove(event) {
                var method = $element.attr("ng-touchmove");
                $scope.$event = event;
                $scope.$apply(method);
            }

        }]
    };
    })
.directive("ngTouchend", function () {
    return {
        controller: ["$scope", "$element", function ($scope, $element) {

            $element.bind("touchend", onTouchEnd);
            function onTouchEnd(event) {
                var method = $element.attr("ng-touchend");
                $scope.$event = event;
                $scope.$apply(method);
            }

        }]
    };
    });

function Authorization() {

    this.authorized = false,
    this.memorizedState = null;

    function clear() {
        this.authorized = false;
        this.memorizedState = null;
    }

    function go(fallback) {
        this.authorized = true;
        var targetState = this.memorizedState ? this.memorizedState : fallback;
        $state.go(targetState);
    }

    return {
        authorized: this.authorized,
        memorizedState: this.memorizedState,
        clear: clear,
        go: go
    };

}

function WizardData() {
    var data =
    {
        "travellers": [],
        "harnesses": [],
        "ropes": [],
        "hlleAs": [],
        "legs": [],
        "ppEs": [],
        "name": null,
        "reference": null,
        "numberOfUsers": 1,
        "locationMetrics": [],
        "projectId": null,
        "systemTypeId": null,
        "applicationLocationId": null,
        "id": "",
        "selectedPPEType": null,
        "layoutType": null
    };
    var service = {
        set: set,
        get: get
    };
    return service;

    function set(param) {
        data = param;
    }
    function get() {
        return data;
    }
}

function Common(AppHttp, $http, $filter, AppStorage, AppCookies, $rootScope, $window, $uibModal, $state) {
     var vm = this;
    var service = {
        getStandarts: getStandarts,
        getMetrics: getMetrics,
        getReference: getReference,
        removeDataSourceById: removeDataSourceById,
        checkExistReference: checkExistReference,
        checkExistName: checkExistName,
        splitCode: splitCode,
        splitKey: splitKey,
        getNameById: getNameById,
        getCustomerNameById: getCustomerNameById,
        getResourceIndex: getResourceIndex,
        filterPhoto: filterPhoto,
        filterDocument: filterDocument,
        viewDocument: viewDocument,
        downLoadDocument: downLoadDocument,
        openViewerDocument: openViewerDocument,
        openViewerReport:openViewerReport,
        convertToRadians: convertToRadians,
        convertToDegrees: convertToDegrees,
        sin: sin,
        cos: cos,
        checkUserRole:checkUserRole,
        getSystemTypes: getSystemTypes,
        filterProductBaseType:filterProductBaseType,
        getApplicationLocations: getApplicationLocations,
        convertDate: convertDate,
        getProjectById: getProjectById,
        navigateToProject: navigateToProject,
        getAplicationLocationById: getAplicationLocationById,
        getSytemTypeNameById: getSytemTypeNameById,
        stringFormat: stringFormat,
        generateReference: generateReference,
        hideShowLoading: hideShowLoading
    };
    return service;
    
    function hideShowLoading(id) {
        var state = document.getElementById(id).style.display;
        if (state === "block") {
            document.getElementById(id).style.display = "none";
        }
    }

     function generateReference(idLength) {
            var alphabet = "0123456789abcdefghijklmnopqrstuvwxyz";
            var rtn = "";
            for (var i = 0; i < idLength; i++) {
                rtn += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
            }
            return rtn;
        }

    function stringFormat(fmtstr) {
        var args = Array.prototype.slice.call(arguments, 1);
        return fmtstr.replace(/\{(\d+)\}/g, function (match, index) {
            return args[index];
        });
    }

    function navigateToProject(item)
    {
        var projReference = item.reference.toString().substring(0, 12) + ".html";
        while (projReference.indexOf(" ") >= 0) {
            projReference = projReference.replace(" ", "-");
        }
        var name = item.name.replace("  ", "-");
        while (name.indexOf(" ") >= 0) {
            name = name.replace(" ", "-");
        }
        $state.go("projects.detail", { projectName: name, projectId: item.id, projectItem: item, reference: projReference }, { notify: true });
    }

    function convertDate(usDate) {
        var parseDate = new Date(usDate);
        var yyyy = parseDate.getFullYear();
        var mm = parseDate.getMonth() < 9 ? "0" + (parseDate.getMonth() + 1) : (parseDate.getMonth() + 1); // getMonth() is zero-based
        var dd = parseDate.getDate() < 10 ? "0" + parseDate.getDate() : parseDate.getDate();
        var concatDate = "".concat(yyyy).concat("-").concat(mm).concat("-").concat(dd);
        return concatDate;
    }

    function checkUserRole() {
        vm.currentUser = AppCookies.get("user");
        vm.listRoles = vm.currentUser.UserRoles;
        $rootScope.isOrganisationAdminRole = false;
        $rootScope.isOrganisationUserRole = false;
        $rootScope.is3Madmin = false;
        $rootScope.currentUseName = vm.currentUser.UserName;
        $rootScope.userId = vm.currentUser.UserId;
        $rootScope.CompanyId = vm.currentUser.CompanyId;
        $rootScope.projectDeletePermission = false;

        //check uer isOrganisationAdminRole 
        var key;
        for (key in vm.listRoles) {
            if (vm.listRoles.hasOwnProperty(key)) {
                if (vm.listRoles[key].RoleName.toLowerCase().indexOf("3madmin") < 0 && vm.listRoles[key].RoleName.toLowerCase().indexOf("organisationadmin") >= 0) {
                    $rootScope.isOrganisationAdminRole = true;
                    break;
                };
            }
        }

        //check uer isOrganisationUserRole 
        for (key in vm.listRoles) {
            if (vm.listRoles.hasOwnProperty(key)) {
                if (vm.listRoles[key].RoleName.toLowerCase().indexOf("3madmin") < 0 && vm.listRoles[key].RoleName.toLowerCase().indexOf("organisationadmin") < 0 && vm.listRoles[key].RoleName.toLowerCase().indexOf("organisationuser") >= 0) {
                    $rootScope.isOrganisationUserRole = true;
                    break;
                };
            }
        }

        //check uer isOrganisationUserRole 
        for (key in vm.listRoles) {
            if (vm.listRoles.hasOwnProperty(key)) {
                if (vm.listRoles[key].RoleName.toLowerCase().indexOf("3madmin") >= 0) {
                    $rootScope.is3Madmin = true;
                    break;
                };
            }
        }
        
        //check uer isOrganisationAdminRole 
        if ($rootScope.isOrganisationAdminRole) {
            $rootScope.isOrganisationUserRole = false;
            $rootScope.projectDeletePermission = true;
        }

        //check uer is3Madmin 
        if ($rootScope.is3Madmin) {
            $rootScope.projectDeletePermission = true;
            $rootScope.isOrganisationAdminRole = false;
            $rootScope.isOrganisationUserRole = false;
        }
    }
    
    function cos(x) {
        return Math.cos(x) + 8 - 8;
    }
    function sin(x) {
        return Math.sin(x) + 8 - 8;
    }

    function convertToRadians(degrees) {
        return (degrees * Math.PI) / 180;
    }

    function convertToDegrees(radians) {
        return (radians * 180) / Math.PI;
    }

    function openViewerDocument(item) {
        var data = item
            ? item
            : {
                "blobUrl": null
            };
        var modalInstance = $uibModal.open({
            animation: this.animationsEnabled,
            ariaLabelledBy: "modal-title",
            ariaDescribedBy: "modal-body",
            templateUrl: "app/components/common/viewDocument.html",
            controller: "viewDocumentCtrl",
            controllerAs: "viewDocument",
            backdrop: "static",
            size: "lg",
            resolve: {
                data: function () {
                    return data;
                }
            }
        });
        //when call function save on popup then call post back this function: 
        modalInstance.result.then(function (respone) {

        }, function () {
            //console.log('call cancel!');
        });
    }

     function openViewerReport(item) {
        var data = item
            ? item
            : {
                "blobUrl": null,
                "extFile": null
            };
        var modalInstance = $uibModal.open({
            animation: this.animationsEnabled,
            ariaLabelledBy: "modal-title",
            ariaDescribedBy: "modal-body",
            templateUrl: "app/components/common/viewReport.html",
            controller: "viewReportCtrl",
            controllerAs: "viewReport",
            backdrop: "static",
            size: "lg",
            resolve: {
                data: function () {
                    return data;
                }
            }
        });
        //when call function save on popup then call post back this function: 
        modalInstance.result.then(function (respone) {

        }, function () {
            //console.log('call cancel!');
        });
    };


    function viewDocument(url) {
        if (url != undefined) {
            $window.open(url, "_blank");
        }
    }

    function downLoadDocument(item) {
        $http.get(item.blobUrl.replace("_xs", ""), {
            responseType: "arraybuffer"
        }).then(function (data) {
             var filename = item.name;
             // to download in IE >10 versions
             // Try using msSaveBlob if supported
             var blob = new Blob([data.data], {
                 type: "application/pdf"
             });

             if (navigator.msSaveBlob)
                 navigator.msSaveBlob(blob, filename);
             else {
                 var e = document.createEvent("MouseEvents"),
                 a = document.createElement("a");
                 a.download = filename;
                 a.href = window.URL.createObjectURL(blob);
                 a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
                 e.initEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                 a.dispatchEvent(e);
                 // window.URL.revokeObjectURL(url); // clean the url.createObjectURL resource
             }

         }, function (data, status) {
             $scope.info = "Request failed with status: " + status;
         });
    };

    function filterProductBaseType(listSource,productTypeId) {
        var listTemp = [];
        for (var key in listSource) {
            if (listSource.hasOwnProperty(key)) {
                if (listSource[key].productTypeId === productTypeId) {
                    listTemp.push(listSource[key]);
                };
            }
        }
        return listTemp;
    }

    function getProjectById(listSource, id) {
               
        for (var key in listSource) {
            if (listSource.hasOwnProperty(key)) {
                if (listSource[key].id == id) {
                      return listSource[key];
                };
            }
        }
        return null;        
    }

    function filterPhoto(listSource) {
        var listTemp = [];
        for (var key in listSource) {
            if (listSource.hasOwnProperty(key)) {
                if (listSource[key].type.indexOf("image") !== -1) {
                    listTemp.push(listSource[key]);
                };
            }
        }
        return listTemp;
    }

    function filterDocument(listSource) {
        var listTemp = [];
        for (var key in listSource) {
            if (listSource.hasOwnProperty(key)) {
                if (listSource[key].type.indexOf("image") === -1) {
                    listTemp.push(listSource[key]);
                };
            }
        }
        return listTemp;
    }


    function getResourceIndex(resources, resource) {
        var index = -1;
        for (var i = 0; i < resources.length; i++) {
            if (resources[i].id.toString() === resource.id.toString()) {
                index = i;
            }
        }
        return index;
    }

    function getCustomerNameById(id) {
        if (AppStorage.get("customers") !=null)
            return getNameById(AppStorage.get("customers"), id);
        else {
            return null;
        }
    }

    function getAplicationLocationById(id) {
        if (AppStorage.get("applicationLocations") != null)
            return getNameById(AppStorage.get("applicationLocations"), id);
        else {
            return null;
        }
    }
    
    function getSytemTypeNameById(id) {
        if (AppStorage.get("systemTypes") != null)
            return getNameById(AppStorage.get("systemTypes"), id);
        else {
            return null;
        }
    }
    
    function getNameById(source, idValue) {
        if (source != undefined && source.length >0) {
            var result = $filter("filter")(source, { id: idValue })[0];
            if (result != undefined) return result.name;
        }
        return "";
    }
    function splitCode(string, index) {
        var array = string.split("-");
        return array[index];
    }

    function splitKey(string, index) {
        var array = string.split(".");
        return array[index];
    }

    //remove data source of kendo grid view
    function removeDataSourceById(dataSource, id) {
        var raw = dataSource.data();
        var length = raw.length;
        var item, i;
        for (i = length - 1; i >= 0; i--) {
            item = raw[i];
            if (item.id.toString() === id.toString()) {
                raw.splice(i, 1);
                return true;
            }
        }
        return false;
    }

    function getApplicationLocations() {
        return AppHttp.get(apiUrl + "common/ApplicationLocations");
    }

    function getSystemTypes() {
        return AppHttp.get(apiUrl + "common/SystemTypes");
    }

    function getStandarts(time) {
        return AppHttp.get(apiUrl + "common/Standards", time);
    }

    //get Unit
    function getMetrics(time) {
        return AppHttp.get(apiUrl + "common/Units", time);
    }

    function getReference(time) {

        return AppHttp.get(apiUrl + "Project/UniqueString", time);

    }

    function checkExistReference(strRefer) {

        return AppHttp.get(apiUrl + "Project/IsReferenceExist?reference=" + strRefer);
    }

    function checkExistName(strName) {

        return AppHttp.get(apiUrl + "Project/IsNameExist?name=" + strName);
    }

}

function AppHttp($http, $q, AppCookies) {
    var token = "";//"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE0ODM5ODUyMTksImV4cCI6MTQ4NDA5MjgwMCwiaWF0IjoxNDgzOTg1MjE5LCJpc3MiOiJtbW0ucHNkLmFzIn0.JEehfffmviSBcfSkEmuLb4mz0M1_VT6NsdKC28M4IVk";
    var service = {
        setToken: setToken,
        get: get,
        put: put,
        delete: del,
        post: post,
        getNoConfig: getNoConfig
    };
    return service;

    function setToken(value) {
        token = value;
    }

    //function getHttpConfig() {
    //    return {
    //        headers: {
    //            Authorization: token,
    //            'Content-Type': "application/json"
    //        },
    //        cache: false,
    //        timeout: 5 * 10000
    //    };
    //}

    function getHttpConfig(time) { // time is minutes number

        token = "Bearer " + AppCookies.get("user").Token;
        if (time > 0) {
            return {
                headers: {
                    Authorization: token,
                    'Content-Type': "application/json"
				},
				cache: true,
                timeout: time * 600 * 1000
            };
        } else {
            return {
                headers: {
                    Authorization: token,
                    'Content-Type': "application/json"
                },
                cache: false,
				timeout: time * 600 * 1000
            };
        }


    }

    //function getHttpConfig() {

    //    token = 'Bearer ' + AppCookies.get('user').Token;
    //    return {
    //        headers: {
    //            Authorization: token,
    //            'Content-Type': "application/json"
    //        },
    //        cache: false,
    //        timeout: 60 * 60 *1000
    //    };
    //}

    function post(url, data) {
        var config = getHttpConfig();
        return $http.post(url, data, config);
    };

    function put(url, data) {
        var config = getHttpConfig();
        return $http.put(url, data, config);
    };

    function get(url, time) {
        var config = getHttpConfig(time);
        return $http.get(url, config);
    };

    function getNoConfig(url) {
        return $http.get(url);
    };

    function del(url, data) {
        var config = getHttpConfig();
        if (data) config.data = data;
        return $http.delete(url, config);
    };
}

function AppCookies($cookies) {
    var service = {
        set: set,
        get: get,
        delete: del,
        deleteAll: deleteAll
    };

    return service;

    function set(key, value) {
        value = Base64.encode(JSON.stringify(value));
        $cookies.put(key, value);
    }

    function get(key) {
        var saved = $cookies.get(key);
        return saved ? JSON.parse(Base64.decode(saved)) : false;
    }

    function del(key) {
        $cookies.remove(key);
    }

    function deleteAll() {
        var cookies = $cookies.getAll();
        angular.forEach(cookies, function (v, k) {
            del(k);
        });
    }
}

function AppStorage() {
    var service = {
        set: set,
        get: get,
        delete: del,
        deleteAll: deleteAll
    };

    return service;

    function set(key, value) {
        if (typeof (Storage) !== "undefined") {
            value = Base64.encode(JSON.stringify(value));
            localStorage.setItem(key, value);
        } else {
            showToast("system_this_browser_does_not_support_web_storage_function_to_improve_performance");
            return false;
        }
    }

    function get(key) {
        if (typeof (Storage) !== "undefined") {
            var saved = localStorage[key];
            return saved ? JSON.parse(Base64.decode(saved)) : false;
        } else {
            showToast("system_this_browser_does_not_support_web_storage_function_to_improve_performance");
            return false;
        }
    }

    function del(key) {
        if (typeof (Storage) !== "undefined")
            localStorage.removeItem(key);
        else {
            showToast("system_this_browser_does_not_support_web_storage_function_to_improve_performance");
            return false;
        }
    }

    function deleteAll() {
        if (typeof (Storage) !== "undefined")
            //useful for logout or remove item (user, department, group ...) in the live server
            localStorage.clear();
        else {
            showToast("system_this_browser_does_not_support_web_storage_function_to_improve_performance");
            return false;
        }
    }
}

function AppDBStorage(AppHttp) {
    var MODULE = BASEURL + "user/";
    var service = {
        set: set,
        get: get
    };

    return service;

    function set(employee_id, module_id, key, value, setting_id) {
        var obj = {
            "employee_id": employee_id,
            "module_id": module_id,
            "key": key,
            "value": value
        };

        if (!employee_id || !module_id || !key || !value) {
            console.log("AppDBStorage.set - cannot set value with the wrong value!");
            return false;
        }

        if (!setting_id)
            setting_id = 0;

        return AppHttp.post(MODULE + "updateEmployeeSetting/" + setting_id, obj);
    }

    function get(module_id) {
        if (!module_id) {
            console.log("AppDBStorage.get - cannot get value with the wrong module_id's value!");
            return false;
        }

        return AppHttp.get(MODULE + "getEmployeeSettings/" + module_id);
    }
}

function AppToast($mdToast, $filter) {
    var service = {
        show: show
    };
    var pinTo = "bottom right";

    return service;

    function show(text, button) {
        button = "close";
        var toast = $mdToast.simple()
            .textContent(text)
            .action(button)
            .highlightAction(true)
            .highlightClass("md-primary")
            .position(pinTo);
        return $mdToast.show(toast);
    }
}

function AppDialog($mdDialog, $filter) {
    var pinTo = "bottom right";

    var service = {
        show: show,
        showConfirm: showConfirm,
        showAlert: showAlert
    };

    return service;
    /*
    //// controller : DialogController
    //// templateUrl : app/ui/dialog/modal.html
    //// targetEvent : ev
    //// data : data
    */
    function show(config) {
        return $mdDialog.show({
            controller: config.controller,
            controllerAs: "modal",
            templateUrl: config.templateUrl,
            parent: angular.element(document.body),
            targetEvent: config.targetEvent,
            disableParentScroll: true,
            clickOutsideToClose: false,
            autoWrap: false,
            fullscreen: false,
            locals: {
                data: config.data
            }
        });
    }


    function showConfirm(config) {
        return _alert(config, false);
    }


    function showAlert(txt) {
        var config = {
            title: "alert",
            content: txt
        };

        return _alert(config, true);
    }

    function _alert(config, is_view_alert) {
        // Appending dialog to document.body to cover sidenav in docs app
        var confirm = $mdDialog.confirm({
            templateUrl: "app/components/layouts/alert.html",
            controller: dialogConfirmController,
            locals: {
                data: config,
                view_alert: is_view_alert
            }
        }).targetEvent(config.targetEvent);

        return $mdDialog.show(confirm);
    }
}

function dialogConfirmController($mdDialog, $scope, $filter, data, view_alert) {
    var vm = $scope;

    vm.data = data;
    vm.view_alert = view_alert;

    vm.cancel = function () {
        $mdDialog.cancel();
    };

    vm.ok = function () {
        $mdDialog.hide();
    };
}

function AppDatePicker($mdDateLocale) {
    var service = {
        setDateFormat: setDateFormat
    };

    function setDateFormat(format) {
        $mdDateLocale.formatDate = function (date) {
            var m = moment(date);
            return (date != null && m.isValid()) ? m.format(format) : "";
        };
    }

    return service;
}

function AppLoading($timeout) {
    var loading = false;
    var service = {
        show: show,
        hide: hide,
        get: getLoadingStatus
    };

    return service;

    function show() {
        loading = true;
    }

    function hide(timeout) {
        loading = false;
        //timeout = timeout || 1000;
        //$timeout(function () {
        //    loading = false;
        //}, timeout);
    }

    function getLoadingStatus() {
        return loading;
    }
}

function AppHelper() {
    var service = {
        convertHex: convertHex
    };

    return service;

    function convertHex(hex, opacity) {
        hex = hex.replace("#", "");
        var r = parseInt(hex.substring(0, 2), 16);
        var g = parseInt(hex.substring(2, 4), 16);
        var b = parseInt(hex.substring(4, 6), 16);

        var result = "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
        return result;
    }
}
  