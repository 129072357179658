angular.module('app').controller('reportTemplateController', reportTemplateController);

function reportTemplateController(ReportTemplate, AppStorage, AppCookies, Common, $state, $stateParams, $scope, $rootScope, $q, $filter, AppLoading, $uibModal) {
    var vm = this;
    $rootScope.isShowButtonHelp = false;
    vm.error = false;
    vm.save = save;
    vm.deletePage = del;
    vm.listCountries = AppStorage.get("countries");
    vm.form = {
        reportTypeId: 0,
        countryId: 0
    };
    vm.page = {
        id: 0,
        html: ""
    };
    vm.lstPage = [];
    vm.getHtml = getHtml;
    vm.pageNumbers = 1;
    vm.addPage = addPage;
    vm.oldPage = 1;
    vm.changePage = function changePage(oldValue) {
        var newValue = parseInt(vm.pageNumbers);
        for (var i = 0; i < vm.lstPage.length; i++) {
            if (vm.lstPage[i].id == oldValue) {
                var htmldata = editor.getHtml();
                var cssdata = editor.getCss();
                var html = htmldata + '<style>' + cssdata + '</style>';
                var newHtml = html.split(/"/).join("'");
                vm.lstPage[i].html = newHtml;
            }
        }
        for (var i = 0; i < vm.lstPage.length; i++) {
            if (vm.lstPage[i].id == newValue) {
                editor.setComponents(vm.lstPage[i].html);
            }
        }
        vm.oldPage = newValue;
    }

    function addPage() {
        var pageCount = vm.lstPage.length;
        var newPage = {
            id: pageCount + 1,
            html: ""
        };
        vm.lstPage.push(newPage);
        for (var i = 0; i < vm.lstPage.length; i++) {
            if (vm.lstPage[i].id == vm.oldPage) {
                var htmldata = editor.getHtml();
                var cssdata = editor.getCss();
                var html = htmldata + '<style>' + cssdata + '</style>';
                var newHtml = html.split(/"/).join("'");
                vm.lstPage[i].html = newHtml;
            }
        }
        for (var i = 0; i < vm.lstPage.length; i++) {
            if (vm.lstPage[i].id == newPage.id) {
                vm.oldPage = newPage.id;
                editor.setComponents(vm.lstPage[i].html);
                vm.pageNumbers = newPage.id;
            }
        }
    }
    function getHtml(data) {
        var a = data;
        vm.lstPage = JSON.parse(a);
    }
    vm.lstDataTag = "";
    vm.htmlReportTemplate = "";
    vm.templateHtml = {
        id: 0,
        html: "",
        reportTypeId: 0,
        countryId: 0
    };
    function save() {
        AppLoading.show();
        vm.error = false;
        ReportTemplate.update(vm.templateHtml).then(function (response) {
            AppLoading.hide(1);
            alert($filter('translate')("Update_ReportTemplate_Successfully"));
        }).catch(function (error) {
            AppLoading.hide(1);
            vm.error = true;
            vm.message = [error.statusText];
        });
    };
    function del() {
        var result = confirm($filter('translate')("Delete_Page_ReportTemplate"));
        if (result) {
            for (var i = 0; i < vm.lstPage.length; i++) {
                var page = parseInt(vm.pageNumbers);
                if (vm.lstPage[i].id == page) {
                    vm.lstPage.splice(page - 1, 1);
                    if (page == 1) 
                        vm.pageNumbers = vm.oldPage;
                    else
                        vm.pageNumbers = vm.oldPage - 1;
                    for (var i = 0; i < vm.lstPage.length; i++) {
                        if (vm.lstPage[i].id >= vm.oldPage) {
                            vm.lstPage[i].id = vm.lstPage[i].id - 1;
                        }
                    }
                }
            }
            vm.changePage();
        }
    }
    // Grapes Js
    var images = [
        'https://esdsblobstorage.blob.core.windows.net/esds/ReportResources/icon.png',
        'https://esdsblobstorage.blob.core.windows.net/esds/ReportResources/Logo.png',
        'https://esdsblobstorage.blob.core.windows.net/esds/ReportResources/metric-roof.png',
        'https://esdsblobstorage.blob.core.windows.net/esds/ReportResources/output-roof-lanyard.png',

    ];
    var editor = grapesjs.init({
        showOffsets: 1,
        noticeOnUnload: 0,
        container: '#gjs',
        height: '100%',
        fromElement: true,
        style: '#wrapper { position: relative; height: 0; padding-bottom: 150%; background: #fff; margin-bottom:40px;  } #wrapper > div { position: absolute; top: 0; left: 0; right: 0; bottom: 0;height:100%; }',
        plugins: ['gjs-preset-webpage'], 
        pluginsOpts: {
            'gjs-preset-webpage': {
                modalImportTitle: "{{'Title_ImportTemplate' | translate }}",
                modalImportLabel: "<div style='margin-bottom: 10px; font-size: 13px;'>{{'Label_ImportTemplate' | translate }}</div>",
                modalImportContent: function (editor) {
                    return '<div>' + '<br/>' +" {{'Content_ImportTemplate' | translate }} "+ '</div>' 
                }
            }
        },
        storageManager: {
            autoload: 0,
            type: 'remote',
            autosave: false,
            urlLoad: 'dragdrop/edit/id',
            contentTypeJson: true,
        },
        assetManager: { assets: images },
        styleManager: {
            clearProperties: 1,
            sectors: [{
                name: 'General',
                open: false,
                buildProps: ['float', 'display', 'position', 'top', 'right', 'left', 'bottom']
            }, {
                name: 'Dimension',
                open: false,
                buildProps: ['width', 'height', 'max-width', 'min-height', 'margin', 'padding'],
            }, {
                name: 'Typography',
                open: false,
                buildProps: ['font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-shadow'],
            }, {
                name: 'Decorations',
                open: false,
                buildProps: ['border-radius-c', 'background-color', 'border-radius', 'border', 'box-shadow', 'background'],
            }, {
                name: 'Extra',
                open: false,
                buildProps: ['transition', 'perspective', 'transform'],
            }
            ],
        },
    });


    editor.BlockManager.getAll().reset();

    editor.Panels.addButton
        ('options',
        [{
            id: 'save-db',
            className: 'fa fa-floppy-o',
            command: 'save-db',
            attributes: { title: 'Save DB' }
        }]
        );

    editor.Commands.add
        ('save-db',
        {
            run: function (editor, sender) {
                sender && sender.set('active'); // turn off the button
                var htmldata = editor.getHtml();
                var cssdata = editor.getCss();
                if (vm.templateHtml.id < 1) {
                    alert($filter('translate')("Select_Template_Report_invalid"))
                } else {
                    for (var i = 0; i < vm.lstPage.length; i++) {
                        if (vm.lstPage[i].id == vm.oldPage) {
                            var htmldata = editor.getHtml();
                            var cssdata = editor.getCss();
                            var html = htmldata + '<style>' + cssdata + '</style>';
                            var newHtml = html.split(/"/).join("'");
                            vm.lstPage[i].html = newHtml;
                        }
                    }
                    vm.templateHtml.html = JSON.stringify(vm.lstPage);
                    vm.save();
                }
            }
        });

    editor.BlockManager.add('one-column', {
        category: "Basic",
        label: '1 Column',
        attributes: { class: 'gjs-fonts gjs-f-b1' },
        content: '<div style="display: table;padding-top: 10px;padding-right: 10px;padding-bottom: 10px;padding-left: 10px;width: 100%;" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row">' +
        '<div style="width: 8%;display: table-cell;height: 75px;" data-gjs-draggable=".row"></div>' +
        '</div>',
    })

    editor.Commands.add('canvas-clear', function () {
        if (confirm('Are you sure to clean the canvas?')) {
            var comps = editor.DomComponents.clear();
            setTimeout(function () { localStorage.clear() }, 0)
        }
    });

    editor.BlockManager.add('two-column', {
        category: "Basic",
        label: '2 Columns',
        attributes: { class: 'gjs-fonts gjs-f-b2' },
        content: '<div style="display: table;padding-top: 10px;padding-right: 10px;padding-bottom: 10px;padding-left: 10px;width: 100%;" data-gjs-droppable=".cell" data-gjs-custom-name="Row">' +
        '<div style="width: 8%;display: table-cell;height: 75px;" data-gjs-draggable=".row"></div>' +
        '<div style="width: 8%;display: table-cell;height: 75px;" data-gjs-draggable=".row"></div>' +
        '</div>',
    })
    editor.BlockManager.add('three-column', {
        category: "Basic",
        label: '3 Columns',
        attributes: { class: 'gjs-fonts gjs-f-b3' },
        content: '<div style="display: table;padding-top: 10px;padding-right: 10px;padding-bottom: 10px;padding-left: 10px;width: 100%;" data-gjs-droppable=".cell" data-gjs-custom-name="Row">' +
        '<div style="width: 8%;display: table-cell;height: 75px;" data-gjs-draggable=".row"></div>' +
        '<div style="width: 8%;display: table-cell;height: 75px;" data-gjs-draggable=".row"></div>' +
        '<div style="width: 8%;display: table-cell;height: 75px;" data-gjs-draggable=".row"></div>' +
        '</div>',
    })
    editor.BlockManager.add('two-column-3/7', {
        category: "Basic",
        label: '2 Columns 3/7',
        attributes: { class: 'gjs-fonts gjs-f-b37' },
        content: '<div style="display: table;padding-top: 10px;padding-right: 10px;padding-bottom: 10px;padding-left: 10px;width: 100%;" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row">' +
        '<div style="width: 30%;display: table-cell;height: 75px;" data-gjs-draggable=".row"></div>' +
        '<div style="width: 70%;display: table-cell;height: 75px;" data-gjs-draggable=".row"></div>' +
        '</div>',
    })
    editor.BlockManager.add('text', {
        category: "Basic",
        label: 'Text',
        attributes: { class: 'gjs-fonts gjs-f-text' },
        content: '<div style="padding:10px;">This string will be parsed and turned into a Component</div>',
    })
    editor.BlockManager.add('title', {
        category: "Basic",
        label: 'Title',
        attributes: {
            class: 'gjs-fonts gjs-f-text'
        },
        content: '<div style="padding:10px;color:red;with:500px !important;border-bottom:4px solid red">Title</div>',
    })
    editor.BlockManager.add('link', {
        category: "Basic",
        label: 'Link',
        attributes: { class: 'gjs-f-link' },
        content: '<a style="color: #d983a6; ">Link</a>',
    })
    editor.BlockManager.add('image', {
        category: "Basic",
        label: 'Image',
        attributes: { class: 'gjs-fonts gjs-f-image' },
        content: '<img src="" onmousedown="return false" class="gjs-comp-selected">',
    })
    editor.BlockManager.add('quote', {
        category: "Basic",
        label: 'Quote',
        attributes: { class: 'gjs-f-quote' },
        content: '<blockquote style="margin-left: 30px;margin-bottom: 20px;margin-right: 30px;margin-top: 20px;font-style: italic;box-shadow: 0px -5px 5px 0px 0px;color: rgb(119, 119, 119);font-weight: 300;padding-top: 10px;padding-right: 10px;padding-bottom: 10px;padding-left: 10px;" >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ipsum dolor sit </blockquote>',
    })

    // ************ Declare Popup
    vm.open = function (item) {
        var data = item ? item : {
            "reportTemplate": {
                "reportTypeId": 0,
                "countryId": 0
            }
        };
        var modalInstance = $uibModal.open({
            animation: vm.animationsEnabled,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'app/dashboard/generate_report_new/pop_up.html',
            controller: 'PopupReportTemplateCtrl',
            controllerAs: 'popupReportTemplate',
            resolve: {
                data: function () {
                    return data;
                }
            }
        });

        modalInstance.result.then(function (data) {
            AppLoading.show();
            ReportTemplate.getDataTagByReportType(data.reportTypeId).then(function (response) {
                AppLoading.hide();
                vm.lstDataTag = response.data;
                for (var i = 0; i < vm.lstDataTag.length; i++) {
                    if (vm.lstDataTag[i].fieldName == 'Cable' || vm.lstDataTag[i].fieldName == 'PPE' || vm.lstDataTag[i].fieldName == 'Harness' || vm.lstDataTag[i].fieldName == 'Traveller' || vm.lstDataTag[i].fieldName == 'EnergyAbsorber') {
                        editor.BlockManager.add('pPESummary', {
                            category: "PPE Selection",
                            label: 'PPE Summary',
                            attributes: { class: 'gjs-f-table' },
                            content: '<table style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;">' +
                            '<tr style="border: 1px solid #dddddd;">' +
                            '<td style="padding: 8px;text-align: left;">Cable</td>' +
                            '<td style="padding: 8px;text-align: left;">' + " " + '</td>' +
                            '</tr>' +
                            '<tr style="border: 1px solid #dddddd;">' +
                            '<td style="padding: 8px;text-align: left;">PPE</td>' +
                            '<td style="padding: 8px;text-align: left;">' + " " + '</td>' +
                            '</tr>' +
                            '<tr style="border: 1px solid #dddddd;">' +
                            '<td style="padding: 8px;text-align: left;">Harness</td>' +
                            '<td style="padding: 8px;text-align: left;">' + " " + '</td>' +
                            '</tr>' +
                            '<tr style="border: 1px solid #dddddd;">' +
                            '<td style="padding: 8px;text-align: left;">Energy Absorber</td>' +
                            '<td style="padding: 8px;text-align: left;">' + " " + '</td>' +
                            '</tr>' +
                            '<tr style="border: 1px solid #dddddd;">' +
                            '<td style="padding: 8px;text-align: left;">Traveller</td>' +
                            '<td style="padding: 8px;text-align: left;">' + " " + '</td>' +
                            '</tr>' +
                            '</tbody></table>',
                        });
                        editor.BlockManager.add(vm.lstDataTag[i].fieldName, {
                            category: "PPE Selection",
                            label: vm.lstDataTag[i].fieldName,
                            attributes: { class: 'gjs-fonts gjs-f-text' },
                            content: '<span style="padding: 10px;display: table;">' + vm.lstDataTag[i].displayName + '</span>',
                        });
                    } else if (vm.lstDataTag[i].fieldName == 'Diagram' || vm.lstDataTag[i].fieldName == 'Output Diagram' || vm.lstDataTag[i].fieldName == 'Diagram Type' ||
                        vm.lstDataTag[i].fieldName == 'Peak Tension Span' || vm.lstDataTag[i].fieldName == 'Peak HLL Tension' || vm.lstDataTag[i].fieldName == 'Total Fall Distance' ||
                        vm.lstDataTag[i].fieldName == 'Required Clearance Below Platform' || vm.lstDataTag[i].fieldName == 'Peak Force On User') {
                        if (vm.lstDataTag[i].fieldName == 'Diagram' || vm.lstDataTag[i].fieldName == 'Output Diagram') {
                            editor.BlockManager.add('diagramSummary', {
                                category: "Diagram",
                                label: 'PPE Summary',
                                attributes: { class: 'gjs-f-table' },
                                content: '<table style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;">' +
                                '<tr style="border: 1px solid #dddddd;">' +
                                '<td style="padding: 8px;text-align: left;">Peak Tension Span</td>' +
                                '<td style="padding: 8px;text-align: left;">' + " " + '</td>' +
                                '</tr>' +
                                '<tr style="border: 1px solid #dddddd;">' +
                                '<td style="padding: 8px;text-align: left;">Peak HLL Tension</td>' +
                                '<td style="padding: 8px;text-align: left;">' + " " + '</td>' +
                                '</tr>' +
                                '<tr style="border: 1px solid #dddddd;">' +
                                '<td style="padding: 8px;text-align: left;">Total Fall Distance</td>' +
                                '<td style="padding: 8px;text-align: left;">' + " " + '</td>' +
                                '</tr>' +
                                '<tr style="border: 1px solid #dddddd;">' +
                                '<td style="padding: 8px;text-align: left;">Required Clearance Below Platform</td>' +
                                '<td style="padding: 8px;text-align: left;">' + " " + '</td>' +
                                '</tr>' +
                                '</tbody></table>',
                            });
                            editor.BlockManager.add(vm.lstDataTag[i].fieldName, {
                                category: "Diagram",
                                label: vm.lstDataTag[i].fieldName,
                                attributes: { class: 'gjs-fonts gjs-f-text' },
                                content: '<img src="' + vm.lstDataTag[i].displayName + '" alt="' + vm.lstDataTag[i].fieldName + '" height="400" width="auto" style="margin-left:30px;margin-top:30px">'
                            });
                        } else {
                            editor.BlockManager.add(vm.lstDataTag[i].fieldName, {
                                category: "Diagram",
                                label: vm.lstDataTag[i].fieldName,
                                attributes: { class: 'gjs-fonts gjs-f-text' },
                                content: '<span style="padding: 10px;display: table;">' + vm.lstDataTag[i].displayName + '</span>',
                            });
                        }
                    } else if (vm.lstDataTag[i].fieldName == 'Total Energy' || vm.lstDataTag[i].fieldName == 'Absorbed by Lifeline' || vm.lstDataTag[i].fieldName == 'Absorbed by HLLEA' ||
                        vm.lstDataTag[i].fieldName == 'Absorbed by PEA' || vm.lstDataTag[i].fieldName == 'HLLEA Deployment' || vm.lstDataTag[i].fieldName == 'HLLEA % Used' || vm.lstDataTag[i].fieldName == 'PEA Deployment') {
                        editor.BlockManager.add(vm.lstDataTag[i].fieldName, {
                            category: "Fall Analysis",
                            label: vm.lstDataTag[i].fieldName,
                            attributes: { class: 'gjs-fonts gjs-f-text' },
                            content: '<span style="padding: 10px;display: table;">' + vm.lstDataTag[i].displayName + '</span>',
                        });
                    } else if (vm.lstDataTag[i].fieldName == 'End Anchor X' || vm.lstDataTag[i].fieldName == 'End Anchor Y' || vm.lstDataTag[i].fieldName == 'End Anchor Z' ||
                        vm.lstDataTag[i].fieldName == 'Intermediate X' || vm.lstDataTag[i].fieldName == 'Intermediate Y' || vm.lstDataTag[i].fieldName == 'Intermediate Z' ||
                        vm.lstDataTag[i].fieldName == 'Corner  X' || vm.lstDataTag[i].fieldName == 'Corner  Y' || vm.lstDataTag[i].fieldName == 'Corner  Z' ||
                        vm.lstDataTag[i].fieldName == 'Info X' || vm.lstDataTag[i].fieldName == 'Info Y' || vm.lstDataTag[i].fieldName == 'Info Z') {
                        editor.BlockManager.add(vm.lstDataTag[i].fieldName, {
                            category: "Load Analysis",
                            label: vm.lstDataTag[i].fieldName,
                            attributes: { class: 'gjs-fonts gjs-f-text' },
                            content: '<span style="padding: 10px;display: table;">' + vm.lstDataTag[i].displayName + '</span>',
                        });
                    } else {
                        if (vm.lstDataTag[i].fieldName == 'Background System') {
                            editor.BlockManager.add(vm.lstDataTag[i].fieldName, {
                                category: "System Summary",
                                label: vm.lstDataTag[i].fieldName,
                                attributes: { class: 'gjs-fonts gjs-f-text' },
                                content: '<img src="' + vm.lstDataTag[i].displayName + '" alt="' + vm.lstDataTag[i].fieldName + '" with="100%" style="margin-left:30px;margin-top:30px">'
                            });
                        } else {
                            editor.BlockManager.add(vm.lstDataTag[i].fieldName, {
                                category: "System Summary",
                                label: vm.lstDataTag[i].fieldName,
                                attributes: { class: 'gjs-fonts gjs-f-text' },
                                content: '<span style="padding: 10px;display: table;">' + vm.lstDataTag[i].displayName + '</span>',
                            });
                        }
                    }
                }
            }); 
            vm.getHtml(data.html);
            vm.templateHtml.id = data.id;
            vm.templateHtml.html = data.html;
            vm.templateHtml.reportTypeId = data.reportTypeId;
            vm.templateHtml.countryId = data.countryId;
            vm.oldPage = 1;
            editor.setComponents(vm.lstPage[0].html);
        }, function () {
            console.log('call cancel!');
        });
    };
    vm.open();

}

//declare controller for poppuProject

angular.module('app').controller('PopupReportTemplateCtrl', function (AppStorage, ReportTemplate, Common, AppLoading, Country, Language, Product, $scope, $uibModalInstance, data) {
    var vm = this;
    vm.error = false;
    vm.message = [];

    vm.lstCountries = AppStorage.get("countries");
    vm.lstReportTypes = AppStorage.get("reportTypes");
    
    if (vm.lstReportTypes == undefined || vm.lstReportTypes.length == undefined || vm.lstReportTypes.length <= 0)
        AppLoading.show();
        ReportTemplate.getAllReportType().then(function (response) {
            vm.lstReportTypes = response.data;
            AppStorage.set("reportTypes", response.data);
            AppLoading.hide(1);
        }).catch(function (error) {
            AppLoading.hide(1);
            vm.error = true;
            vm.message = [error.statusText];
        });

    $scope.form = {
        "reportTemplate": {
            "reportTypeId": 0,
            "countryId": 0
        }
    }

    vm.cancel = cancel;
    function cancel() {
        $uibModalInstance.dismiss('cancel');
    };

    vm.next = next;

    function next() {
        AppLoading.show();
        var errMessage = ReportTemplate.validReportTemplate($scope.form);
        if (errMessage.length > 0) {
            vm.error = true;
            vm.message = errMessage;
            AppLoading.hide(1);
        } else {
            vm.error = false;
            ReportTemplate.getTemplate($scope.form).then(function (response) {
                AppLoading.hide(1);
                $uibModalInstance.close(response.data);
            }).catch(function (error) {
                AppLoading.hide(1);
                vm.error = true;
                vm.message = [error.statusText];
            });
        }
    }
});