angular.module('app').controller('userEditController', userEditController);

function userEditController($scope, $rootScope, Role, AppStorage, AppCookies, Company, AppLoading, Account, $filter) {
  var vm = this;
    $rootScope.isShowButtonHelp = false;
    vm.update = update;
    vm.create = create;

    vm.listCompanyOfCurrentUser = [];
    vm.currentUser = AppCookies.get('user');
    vm.listRoles = vm.currentUser.UserRoles;
    vm.CompanyId = vm.currentUser.CompanyId;
    vm.CompanyName = vm.currentUser.CompanyName;
    vm.listCompanyOfCurrentUser.push({ "CompanyId": vm.CompanyId, "Name": vm.CompanyName });

    $scope.addRole = function () {
        if ($scope.selectedRole != undefined) {
            $scope.userRoles.push($scope.selectedRole);
        }
    }
    vm.passwordExpirationDate = "2019-12-31T00:00:00";
    vm.accountExpirationDate = "2019-12-31T00:00:00";
    $scope.userRoles = [];
    vm.changepasswordForm = {
        UserName: '', OldPassword: '', NewPassword: '', ConfirmPassword: ''
    };
    vm.form = {};
    Account.getCurrentUserInformation().then(function (response) {
      vm.form = response.data;
      if (response.data != null) {
          vm.form.CompanyId = response.data.CompanyId.toString();
          vm.passwordExpirationDate = response.data.PasswordExpirationDate;
          vm.accountExpirationDate = response.data.AccountExpirationDate;
      }
    }, function (error) {
      alert([error.statusText]);
    });
    vm.lstLanguages = AppStorage.get("languages");
    vm.roleOfUser = "3MAdmin";
    vm.getUserRoles = function () {
        AppLoading.show();
        vm.error = false;
        Role.gets().then(function (response) {
            AppLoading.hide();
            var listSource = response.data;
            var listTemp = [];
            for (var key in listSource) {
                if (listSource.hasOwnProperty(key)) {
                    if ($rootScope.isOrganisationAdminRole) {
                        if (listSource[key].Name.indexOf("OrganisationAdmin") >= 0 || listSource[key].Name.indexOf("OrganisationUser") >= 0) {
                            listTemp.push(listSource[key]);
                        };
                    } else {
                        if (listSource[key].Name.indexOf("3MAdmin") >= 0 || listSource[key].Name.indexOf("OrganisationAdmin") >= 0 || listSource[key].Name.indexOf("OrganisationUser") >= 0) {
                            listTemp.push(listSource[key]);
                        };
                    }
                }
            }
            $scope.userRoles = listTemp;
        }, function (error) {
            AppLoading.hide();
            vm.error = true;
            vm.message = [error.statusText];
        });

    }
    vm.selectedOrganisation = undefined;
    vm.getOrganisations = getOrganisations;
    vm.lstOrganisations = [];
    function getOrganisations() {
        //AppLoading.show();
        vm.error = false;
        Company.gets().then(function (response) {
            var listSource = response.data;
            //filter only show organisation that user is enjoning  $rootScope.companyId;
            if (!$rootScope.is3Madmin) {
                for (var key in listSource) {
                    if (listSource.hasOwnProperty(key)) {
                        if (listSource[key].CompanyId === $rootScope.companyId) vm.lstOrganisations.push(listSource[key]);
                    }
                }
            }
            else
                vm.lstOrganisations = response.data;
            //  bind list company of current user for drop list
            if (vm.lstOrganisations.length == 0) {
                vm.lstOrganisations = vm.listCompanyOfCurrentUser;
                vm.form.CompanyId = vm.CompanyId.toString();
            }
        }, function (error) {
            vm.error = true;
            vm.message = [error.statusText];
        });

    }
    getOrganisations();
    vm.getUserRoles();

    vm.validPassword = validPassword;
    function validPassword() {
        vm.invalidPasswordFlag = false;
        vm.changePasswordFlag = true;
        var validPassword = Account.valiPassword(vm.changepasswordForm.NewPassword);
        if (!vm.changepasswordForm.NewPassword || !validPassword) {
            vm.invalidPasswordFlag = true;
        } else {
            vm.invalidPasswordFlag = false;
        }
    }

    vm.bindDataSubmit = function () {
        var passwordExpire = new Date(vm.passwordExpirationDate);
        var accountExpire = new Date(vm.accountExpirationDate);
        vm.form.PasswordExpirationDate = new Date(passwordExpire.toISOString().split("T")[0]);
        vm.form.AccountExpirationDate = new Date(accountExpire.toISOString().split("T")[0]);
    }

    vm.updatePasswordExpirationDate = function (item) {
        AppLoading.show();
        vm.error = false;
        Account.updateUserInformation(item).then(function (response) {
            AppLoading.hide();
            vm.message = [];
            vm.message.push($filter('translate')("Message_Create_success"));

        }, function (error) {
            AppLoading.hide();
            vm.error = true;
            vm.message = [error.statusText];
        });
    }

    function create(item) {
        AppLoading.show();
        vm.error = false;
        var errMessage = Account.validUser(item);
        if (errMessage.length > 0) {
            AppLoading.hide();
            vm.message = [];
            vm.error = true;
            vm.message = errMessage;
        } else {
            Account.addUser(vm.form).then(function (response) {
                vm.getUserInfor(vm.form.UserName);
            }, function (error) {
                AppLoading.hide();
                vm.error = true;
                vm.message = [error.statusText];
            });
        }
    }
    function update(item) {
        AppLoading.show();
        vm.error = false;
        var errMessage = Account.validUser(item);
        if (errMessage.length > 0) {
            AppLoading.hide();
            vm.message = [];
            vm.error = true;
            vm.message = errMessage;
        } else {
            vm.error = false;
            Account.updateUserInformation(vm.form).then(function (response) {
                AppLoading.hide();
                vm.message = [];
                alert($filter('translate')("Message_Update_success"));
            }, function (error) {
                AppLoading.hide();
                vm.error = true;
                vm.message = [error.data];
            });
        }
    }

	vm.changePassword = function () {
		AppLoading.show();
		vm.changepasswordForm.error = false;
        vm.changepasswordForm.UserName = vm.form.UserName;
        var errMessage = Account.validChangePass(vm.changepasswordForm);
        if (errMessage.length > 0) {
            vm.changepasswordForm.message = [];
            vm.changepasswordForm.error = true;
            vm.changepasswordForm.message = errMessage;
            AppLoading.hide();
        } else {
            vm.error = false;
            Account.changePassword(vm.changepasswordForm).then(function (response) {
                vm.message = [];
                vm.message.push($filter('translate')("Message_Update_success"));
				AppLoading.hide();
				vm.error = true;
            }, function (error) {
                AppLoading.hide();
                var merror = [];
                merror.push("Current password is incorrect");
                vm.changepasswordForm.error = true;
                vm.changepasswordForm.message = merror;
            });
        }
    }
    vm.save = function () {
        vm.bindDataSubmit();
        if (vm.form.UserId == null) {
            vm.create(vm.form);
        } else {
            vm.update(vm.form);
        }
    }

    vm.cancel = function () {
    };

    $scope.updateRoleSelected = function (role) {
        if (role.is_checked) {
            vm.form.UserRoles.push({ "RoleId": role.Id, "RoleName": role.Name });
        }
        else {

            var index = vm.getRoleIndex(vm.form.UserRoles, role);
            if (index >= 0) vm.form.UserRoles.splice(index, 1);

        }
    };

    vm.getRoleIndex = function (resources, item) {
        var index = -1;
        for (var i = 0; i < resources.length; i++) {
            if (resources[i].RoleId.toString() === item.Id.toString()) {
                index = i;
            }
        }
        return index;
    };

    $scope.isRoleSelected = function (role) {
        var isExist = false;
        angular.forEach(vm.form.UserRoles,
            function (formRole) {
                if (formRole.RoleId != undefined && formRole.RoleId.toString() === role.Id.toString())
                    isExist = true;
            });
        return isExist;
    };

    vm.getUserInfor = function (userName) {
        vm.error = false;
        if ($rootScope.currentUseName.toLowerCase() != userName.toLowerCase())
            Account.getUserInfor(userName).then(function (response) {
                //AppLoading.hide();
                vm.userObject = response.data;
                vm.userObject.PasswordExpirationDate = vm.form.PasswordExpirationDate;
                //  call function update user
                vm.updatePasswordExpirationDate(vm.userObject);

            }, function (error) {
                AppLoading.hide();
                vm.error = true;
                vm.message = [error.statusText];
            });
        else
            Account.getCurrentUserInformation().then(function (response) {
                //AppLoading.hide();
                vm.userObject = response.data;
                vm.userObject.PasswordExpirationDate = vm.form.PasswordExpirationDate;
                //  call function update user
                vm.updatePasswordExpirationDate(vm.userObject);

            }, function (error) {
                AppLoading.hide();
                vm.error = true;
                vm.message = [error.statusText];
            });
    }
}