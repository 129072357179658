"use strict";
angular
	.module("app")
	.service("Account", Account)
    .service("Project", Project)
    .service("System", System)
    .service("Organisation", Organisation)
    .service("Company", Company)
    .service("Language", Language)
    .service("Country", Country)
    .service("Product", Product)
    .service("Report", Report)
    .service("ReportTemplate", ReportTemplate)
    .service("Revision", Revision)
    .service("Document", Document)
    .service("ProductType", ProductType)
   .service("Role", Role);


function Revision(AppHttp) {
    var service = {
        getBaseProject: getBaseProject
    };
    return service;

    function getBaseProject(projectId) {
        return AppHttp.get(apiUrl + "RevisionHistory/GetByProjectId?projectId=" + projectId);
    }

    function getRevisionPaging(projectId) {
        return AppHttp.get(apiUrl + "RevisionHistory/getRevisionPaging?projectId=" + projectId);
    }

}

function ProductType(AppHttp) {
    var service = {
        gets: gets
    };
    return service;

    function gets() {
        return AppHttp.get(apiUrl + "ProductType");
    }

}

function Document(AppHttp, $filter) {
    var service = {
        create: create,
        update: update,
        del: del,
        gets: gets,
        getbyId: getbyId,
        getBlobByFilter: getBlobByFilter,
        validDocument: validDocument,
        downloadBlobDocument: downloadBlobDocument
    };
    return service;
    function create(data) {
        return AppHttp.post(apiUrl + "BlobDocument", data);
    }
    function update(data) {
        return AppHttp.put(apiUrl + "BlobDocument", data);
    }

    function getBlobByFilter(condition) {
        return AppHttp.get(apiUrl + "BlobDocument/GetByFilter?languageId=" + condition.languageId + "&unitId=" + condition.unitId + "&standardId=" + condition.standardId);
    }

    function downloadBlobDocument(id) {
        return AppHttp.get(apiUrl + "BlobDocument/DownloadBlobDocument?id=" + id);
    }
    function getbyId(id) {
        return AppHttp.get(apiUrl + "BlobDocument?id=" + id);
    }

    function validDocument(obj) {
        var message = [];
        if (obj.blobDocument.id == null || obj.blobDocument.id == undefined) {
            if (obj.fileDocument.fileContent == undefined || obj.fileDocument.fileContent == null) {
                message.push($filter("translate")("Message_FileContent_invalid"));
            }
        }

        if (obj.blobDocument.standardId == undefined || obj.blobDocument.standardId == null) {
            message.push($filter("translate")("Message_StandardId_invalid"));
        }
        if (obj.blobDocument.languageId == undefined || obj.blobDocument.languageId == null) {
            message.push($filter("translate")("Message_LanguageId_invalid"));
        }
        if (obj.blobDocument.unitId == undefined || obj.blobDocument.unitId == null) {
            message.push($filter("translate")("Message_UnitId_invalid"));
        }

        if (obj.blobDocument.documentProducts.length <= 0) {

            message.push($filter("translate")("Messsage_Product_required"));
        }


        return message;
    }
    function del(data) {
        return AppHttp.delete(apiUrl + "BlobDocument", data);
    }

    function gets(time) {
        return AppHttp.get(apiUrl + "BlobDocument", time);
    }

}

function ReportTemplate(AppHttp, $http, $q, $filter, AppCookies) {
    var service = {
        update: update,
        getTemplate: getTemplate,
        getDataTagByReportType: getDataTagByReportType,
        getAllReportType: getAllReportType,
        validReportTemplate: validReportTemplate
    };
    return service;

    function update(data) {
        return AppHttp.put(apiUrl + 'ReportTemplate', data);
    }

    function getTemplate(data) {
        return AppHttp.get(apiUrl + 'ReportTemplate/GetById?reportTypeId=' + data.reportTemplate.reportTypeId + '&countryId=' + data.reportTemplate.countryId);
    }

    function getDataTagByReportType(reportTypeId) {
        return AppHttp.get(apiUrl + 'ReportTemplate/GetDataTag?reportTypeId=' + reportTypeId);
    }

    function getAllReportType() {
        return AppHttp.get(apiUrl + 'ReportTemplate/GetAllReportTypes');
    }

    function validReportTemplate(obj) {
        var message = [];
        if (obj.reportTemplate.reportTypeId == undefined || obj.reportTemplate.reportTypeId < 1) {
            message.push($filter("translate")("ReportTemplateType_invalid"));
        }
        if (obj.reportTemplate.countryId == undefined || obj.reportTemplate.countryId < 1) {
            message.push($filter("translate")("Messsage_Country_required"));
        }
        return message;
    }
}

function Report(AppHttp, $http, $q, $filter, AppCookies) {
    var service = {
        create: create,
        update: update,
        del: del,
        gets: gets,
        getById: getById,
        validReport: validReport,
        getByProject: getByProject,
        getRecentByProject: getRecentByProject
    };
    return service;

    function create(data) {
        return AppHttp.post(apiUrl + "Report", data);
    }

    function update(data) {
        return AppHttp.put(apiUrl + "Report", data);
    }

    function getById(reportId) {
        return AppHttp.get(apiUrl + "Report/GetById?reportId=" + reportId);
    }

    function validReport(obj) {
        var message = [];
        if (obj.name == undefined || obj.name.length < 1 || obj.reportSystems == undefined || obj.reportSystems.length < 1) {
            message.push($filter("translate")("Message_ReportName_invalid"));
        }

        return message;
    }

    function del(data) {
        return AppHttp.delete(apiUrl + "Report", data);
    }

    function gets(time) {
        return AppHttp.get(apiUrl + "Report", time);
    }

    function getByProject(projectId) {
        return AppHttp.get(apiUrl + "report/GetByProject?projectId=" + projectId);
    }

    function getRecentByProject(projectId, top) {
        return AppHttp.get(apiUrl + "report/GetRecentReports?projectId=" + projectId + "&topRecord=" + top);
    }


}//end Report

function Product(AppHttp, $http, $q, $filter) {
    var service = {
        create: create,
        update: update,
        del: del,
        gets: gets,
        getbyType: getbyType,
        getbyId: getbyId,
        getFilterProducts: getFilterProducts,
        getProductsByPaging: getProductsByPaging,
        validProduct: validProduct,
        getProductsForNewSystem: getProductsForNewSystem
    };
    return service;

    function create(data) {
        return AppHttp.post(apiUrl + "Product", data);
    }

    function update(data) {
        return AppHttp.put(apiUrl + "Product", data);
    }

    function del(data) {
        return AppHttp.delete(apiUrl + "Product", data);
    }

    function gets(time) {
        return AppHttp.get(apiUrl + "Product", time);
    }
    function getProductsByPaging(string) {
        return AppHttp.get(apiUrl + 'Product/GetProductsByPaging?' + string);
    }
    function getbyType(id) {
        return AppHttp.get(apiUrl + "Product/GetByTypeId?id=" + id);
    }

    function getFilterProducts(condition) {
        return AppHttp.get(apiUrl + "Product/GetFilterProducts?countryId=" + condition.countryId + "&unitId=" + condition.unitId + "&standardId=" + condition.standardId + "&applicationLocationId=" + condition.applicationLocationId + "&systemTypeId=" + condition.systemTypeId);
    }

    function getProductsForNewSystem(condition) {
        return AppHttp.get(apiUrl + "Product/GetProductsForNewSystem?countryId=" + condition.countryId + "&unitId=" + condition.unitId + "&standardId=" + condition.standardId + "&applicationLocationId=" + condition.applicationLocationId + "&systemTypeId=" + condition.systemTypeId);
    }

    function getbyId(id) {
        return AppHttp.get(apiUrl + "Product/GetById?productId=" + id);
    }


    function validProduct(obj) {
        var message = [];
        if (obj.product.name == undefined || obj.product.name.length < 1) {
            message.push($filter("translate")("ProductName_invalid"));
        }

        if (obj.product.productTypeId == undefined || obj.product.productTypeId < 1) {
            message.push($filter("translate")("ProductType_invalid"));
        }

        if (!obj.product.id && (obj.avatar.filename == undefined || obj.avatar.filename.length < 1)) {
            message.push($filter("translate")("Avatar_invalid"));
        }

        if (obj.product.productCountries.length <= 0) {

            message.push($filter("translate")("Messsage_Country_required"));
        }
        if (obj.product.productUnits.length <= 0) {

            message.push($filter("translate")("Messsage_unit_required"));
        }
        if (obj.product.productStandards.length <= 0) {

            message.push($filter("translate")("Messsage_standard_required"));
        }
        if (obj.product.productSystemTypes.length <= 0) {

            message.push($filter("translate")("Messsage_systemType_required"));
        }
        if (obj.product.productApplicationLocations.length <= 0) {

            message.push($filter("translate")("Messsage_location_required"));
        }

        return message;
    }
}

function Country(AppHttp, $http, $q, $filter) {
    var service = {
        create: create,
        update: update,
        del: del,
        gets: gets,
        validCountry: validCountry
    };
    return service;
    function validCountry(obj) {
        var message = [];
        if (obj.name == undefined || obj.name.length < 1) {
            message.push($filter("translate")("Message_CountryName_invalid"));
        }
        if (obj.code == undefined || obj.code.length < 1) {
            message.push($filter("translate")("Message_CountryCode_invalid"));
        }
        return message;
    }

    function create(data) {
        return AppHttp.post(apiUrl + "Country/Create", data);
    }

    function update(data) {
        return AppHttp.put(apiUrl + "Country/Update", data);
    }

    function del(data) {
        return AppHttp.delete(apiUrl + "Country/Delete", data);
    }

    function gets(time) {
        return AppHttp.get(apiUrl + "Country/GetAll", time);
    }
}

function Language(AppHttp, $http, $q, $filter, $cookies) {
    var service = {
        create: create,
        update: update,
        del: del,
        gets: gets,
        updateTranslated: updateTranslated,
        validLanguage: validLanguage
    };
    return service;

    function validLanguage(obj) {

        var message = []; 
        if (obj.name == undefined || obj.name.length < 1) {
            message.push($filter("translate")("Message_LanguageName_invalid"));
        }
        if (obj.code == undefined || obj.code.length < 1) {
            message.push($filter("translate")("Message_LanguageCode_invalid"));
        }
        return message;
    }

	function updateTranslated(data) {
		$cookies.put('cachingFlag', true);
        return AppHttp.put(apiUrl + "language/UpdateTranslateData", data);
    }

	function create(data) {
		$cookies.put('cachingFlag', true);
        return AppHttp.post(apiUrl + "language", data);
    }

	function update(data) {
		$cookies.put('cachingFlag', true);
        return AppHttp.put(apiUrl + "language", data);
    }

	function del(data) {
		$cookies.put('cachingFlag', true);
        return AppHttp.delete(apiUrl + "language", data);
    }

	function gets(time) {

		return AppHttp.get(apiUrl + "language", time);
    }
}

function Organisation(AppHttp, $http, $q, $filter) {
    var service = {
        create: create,
        update: update,
        del: del,
        gets: gets,
        getForCurrentUser: getForCurrentUser,
        isExistOrganisation: isExistOrganisation,
        validOrganisation: validOrganisation,
        GetByCompanyId: getByCompanyId

    };
    return service;

    function create(data) {
        return AppHttp.post(apiUrl + "Organisation/Create", data);
    }

    function update(data) {
        return AppHttp.put(apiUrl + "Organisation/UpdateOrganisation", data);
    }

    function del(data) {
        return AppHttp.delete(apiUrl + "Organisation/Delete", data);
    }


    function gets(time) {
        return AppHttp.get(apiUrl + "Organisation", time);
    }
    function getByCompanyId(id) {
        return AppHttp.get(apiUrl + "Organisation/GetByCompanyId?companyId=" + id);
    }

    function isExistOrganisation(companyId) {
        return AppHttp.get(apiUrl + "Organisation/IsExistOrganisation?companyId=" + companyId);
    }

    function getForCurrentUser() {
        return AppHttp.get(apiUrl + "Organisation/CurrentUserOrganisation");
    }

    function validOrganisation(obj) {
        var message = [];
        if (obj.name.length == undefined || obj.name.length < 1) {
            message.push($filter("translate")("OrganisationName_invalid"));
        }

        if (obj.unitId < 1) {
            message.push($filter("translate")("Metric_invalid"));
        }

        if (obj.languageId == undefined || obj.languageId < 1) {
            message.push($filter("translate")("LanguageId_invalid"));
        }

        if (obj.countryId == undefined || obj.countryId < 1) {
            message.push($filter("translate")("CountryId_invalid"));
        }

        if (obj.standardId == undefined || obj.standardId < 1) {
            message.push($filter("translate")("Message_StandardId_invalid"));
        }

        return message;
    }

}

function Company(AppHttp, $http, $q, $filter) {
    var service = {
        create: create,
        update: update,
        del: del,
        gets: gets,
        getForCurrentUser: getForCurrentUser,
        validCompany: validCompany

    };
    return service;

    function create(data) {
        return AppHttp.post(apiUrl + "User/AddCompany", data);
    }

    function update(data) {
        return AppHttp.put(apiUrl + "User/UpdateCompany", data);
    }

    function del(data) {
        return AppHttp.delete(apiUrl + "User/Delete", data);
    }

    function gets() {
        return AppHttp.get(apiUrl + "User/GetCompanyList");
    }

    function getForCurrentUser() {
        return AppHttp.get(apiUrl + "User/CurrentUserOrganisation");
    }

    function validCompany(obj) {
        var message = [];
        if (obj.Name == undefined || obj.Name == null) {
            message.push($filter("translate")("OrganisationName_invalid"));
        }
        return message;
    }

}




function Role(AppHttp, $http, $q, $filter) {
    var service = {
        create: create,
        update: update,
        del: del,
        gets: gets,
        validRole: validRole

    };
    return service;

    function create(data) {
        return AppHttp.post(apiUrl + "User/AddRole", data);
    }

    function update(data) {
        return AppHttp.put(apiUrl + "User/UpdateRoleInformation", data);
    }

    function del(data) {
        return AppHttp.delete(apiUrl + "User/DeleteRole", data);
    }

    function gets() {
        return AppHttp.get(apiUrl + "/User/GetRoleList");

    }

    function validRole(obj) {
        var message = [];
        if (obj.Name == undefined || obj.Name == null || obj.Name.length < 1) {
            message.push($filter("translate")("Message_roleName_invalid"));
        }
        return message;
    }

}

function System(Common, AppHttp, $http, $q, $filter) {
    var service = {
        create: create,
        update: update,
        updateHarness: updateHarness,
        updatePPE: updatePpe,
        updateRope: updateRope,
        updateHLLEA: updateHLLEA,
        updateTraveller: updateTraveller,
        del: del,
		cal: cal,
		calintel: calintel,
		calintelforall: calintelforall,
        getbaseId: getbaseId,
        getbaseProjectId: getbaseProjectId,
        validSystem: validSystem,
        validateNameReference: validateNameReference,
        validateChoseLocation: validateChoseLocation,
        validateChoseMetric: validateChoseMetric,
        validateChoseHarness: validateChoseHarness,
        validateChoseLayout: validateChoseLayout,
        validateChoseLeg: validateChoseLeg,
        validateChoseHea: validateChoseHea,
        validateChoseRope: validateChoseRope,
        validateChosePpe: validateChosePpe,
        validateChoseTraveller: validateChoseTraveller,
        getRecentSystems: getRecentSystems,
        smartComponent: smartComponent,
        validSmartComponent: validSmartComponent,
        checkExistReference : checkExistReference
    };
    return service;

    function checkExistReference(strRefer) {
        return AppHttp.get(apiUrl + "system/IsReferenceExist?reference=" + strRefer);
    }

    function validateChoseLeg(obj) {
        var message = [];
        return message;
    }

    function validateChoseTraveller(obj) {
        var message = [];

        if (obj.travellers.length < 1) {
            message.push($filter("translate")("Message_Traveller_Invalid"));
        }
        return message;

    }

    function validateChoseLocation(obj) {
        var message = [];
        if (obj.applicationLocationId < 1) {
            message.push($filter("translate")("ApplicationLocationId_invalid"));
        }
        return message;
    }
    //valiad Metric
    function validateChoseMetric(obj) {
        var message = [];
        var i;
        if (obj.applicationLocationId === "4") { //in case choose Laocation is Floor
            if (obj.locationMetrics.length < 2) {
                message.push($filter("translate")("LocationMetrics_invalid"));
                return message;
            }
            for (i = 0; i < obj.locationMetrics.length; i++) {
                if (!obj.locationMetrics[i] || obj.locationMetrics[i] == undefined || obj.locationMetrics[i] <= 0) {
                    message.push($filter("translate")("LocationMetrics_invalid"));
                    break;
                }
            }

        }
        else {
            if (obj.locationMetrics.length < 3) {
                message.push($filter("translate")("LocationMetrics_invalid"));
            }
            for (i = 0; i < obj.locationMetrics.length; i++) {
                if (!obj.locationMetrics[i] || obj.locationMetrics[i] == undefined || obj.locationMetrics[i] <= 0) {
                    message.push($filter("translate")("LocationMetrics_invalid"));
                    break;
                }
            }
        }

        return message;
    }

    function validateChoseHarness(obj) {
        var message = [];

        if (obj.harnesses.length < 1) {
            message.push($filter("translate")("Message_Harness_Invalid"));
        }
        return message;

    }


    function validateChosePpe(obj) {
        var message = [];

        if (obj.ppEs.length < 1) {
            message.push($filter("translate")("Message_PPE_Invalid"));
        }
        return message;

    }


    function validateChoseHea(obj) {
        var message = [];

        if (obj.hlleAs.length < 1) {
            message.push($filter("translate")("Message_HEA_Invalid"));
        }
        return message;

    }

    function validateChoseRope(obj) {
        var message = [];
        if (obj.ropes.length < 1) {
            message.push($filter("translate")("Message_Rope_Invalid"));
        }
        return message;
    }

    function validateChoseLayout(obj) {
        var message = [];
        if (obj.layoutType == undefined || obj.layoutType == null) {
            message.push($filter("translate")("Message_Layout_Invalid"));
        }
        return message;
    }

    function validateNameReference(obj) {
        var message = [];
        if (obj.name == null || obj.name == undefined) {
            message.push($filter("translate")("SystemName_invalid"));
        }
        if (obj.reference == null || obj.reference == undefined) {
            message.push($filter("translate")("Reference_invalid"));
        }
        var isExtistReference = Common.checkExistReference(obj.Reference);
        if (!isExtistReference) {
            message.push($filter("translate")("reference_isExist"));
        }

        if (obj.systemTypeId < 1) {
            message.push($filter("translate")("SystemType_invalid"));
        }
        return message;
    }

    function create(data) {
        return AppHttp.post(apiUrl + "system", data);
    }

    function update(data) {
        return AppHttp.put(apiUrl + "system", data);
    }
    function updatePpe(data) {
        return AppHttp.post(apiUrl + "PPE/AddUpdate", data);
    }
    function updateRope(data) {
        return AppHttp.post(apiUrl + "Rope/AddUpdate", data);
    }
    function updateHLLEA(data) {
        return AppHttp.post(apiUrl + "HLLEA/AddUpdate", data);
    }

    function updateTraveller(data) {
        return AppHttp.post(apiUrl + "Traveller/AddUpdate", data);
    }


    function updateHarness(data) {
        return AppHttp.post(apiUrl + "Harness/AddUpdate", data);
    }

    function del(data) {
        return AppHttp.delete(apiUrl + "system", data);
    }

    function cal(data) {
        return AppHttp.post(apiUrl + "system/calculation", data);
    }
	function calintel(data) {
		return AppHttp.post(apiUrl + "system/calculationforintel", data);
	}
	function calintelforall(data) {
		return AppHttp.post(apiUrl + "system/calculationforall", data);
	}
	
    function getbaseId(id) {
        return AppHttp.get(apiUrl + "system?id=" + id);
    }
    function getbaseProjectId(projectId) {
        return AppHttp.get(apiUrl + "system?projectId=" + projectId);
    }

    function getRecentSystems(projectId, top) {
        return AppHttp.get(apiUrl + "system/GetRecentSystems?projectId=" + projectId + "&topRecord=" + top);
    }

    function smartComponent(data) {
        return AppHttp.post(apiUrl + "system/SmartComponentDesignation", data);
    }

    function validSmartComponent(data) {
        var message = [];
        if (data.legs.length < 1 || data.legs == undefined || !data.legs) {
            message.push($filter("translate")("Message_Legs_invalid"));
        }
        return message;
    }

    function validSystem(obj) {
        var message = [];
        if (obj.name == null || obj.name == undefined) {
            message.push($filter("translate")("SystemName_invalid"));
        }

        if (obj.reference == undefined || obj.reference == null) {
            message.push($filter("translate")("Reference_invalid"));
        }

        if (obj.projectId < 1) {
            message.push($filter("translate")("ProjectId_invalid"));
        }

        return message;
    }
}

function Project(AppHttp, Common, $http, $q, $filter) {
    var service = {
        create: create,
        update: update,
        del: del,
        gets: gets,
        getCustomerbyKey: getCustomerbyKey,
        getCustomerbyId: getCustomerbyId,
        getAllCustomer: getAllCustomer,
        getDocumentByProject: getDocumentByProject,
        uploadDocument: uploadDocument,
        downloadProjectDocById: downloadProjectDocById,
        updateDocument: updateDocument,
        updateNote: updateNote,
        getNoteByProject: getNoteByProject,
        delDocument: delDocument,
        delNote: delNote,
        createNote: createNote,
        validProject: validProject,
        validNote: validNote,
        validDocument: validDocument,
        getProjectsByPaging: getProjectsByPaging,
        getRecentProjects: getRecentProjects,
        getRecentNoteByProject: getRecentNoteByProject,
        getProjectById: getProjectById
    };
    return service;
    //begin define funcions in service

    function getProjectById(projectId) {
        return AppHttp.get(apiUrl + "Project/GetProjectById?id=" + projectId);
    }

    function getRecentProjects(top) {
        return AppHttp.get(apiUrl + "Project/GetRecentProjects?topRecord=" + top);
    }

    function getProjectsByPaging(string) {
        return AppHttp.get(apiUrl + "Project/GetProjectsByPaging?" + string);
    }

    function uploadDocument(data) {
        return AppHttp.post(apiUrl + "ProjectDocument", data);
    }
    function updateDocument(data) {
        return AppHttp.put(apiUrl + "ProjectDocument", data);
    }
    function downloadProjectDocById(id) {
        return AppHttp.get(apiUrl + "ProjectDocument/DownloadProjectDocumentById?id=" + id);
    }
    function createNote(data) {
        return AppHttp.post(apiUrl + "Note", data);
    }
    function updateNote(data) {
        return AppHttp.put(apiUrl + "Note", data);
    }

    function create(data) {
        return AppHttp.post(apiUrl + "Project", data);
    }

    function update(data) {
        return AppHttp.put(apiUrl + "Project", data);
    }

    function del(data) {
        return AppHttp.delete(apiUrl + "Project", data);
    }

    function gets() {
        return AppHttp.get(apiUrl + "Project");
    }
    function getCustomerbyKey(data) {
        return AppHttp.get(apiUrl + "Customer/?key=" + data);
    }
    function getAllCustomer() {
        return AppHttp.get(apiUrl + "Customer");
    }
    function getCustomerbyId(id) {
        return AppHttp.get(apiUrl + "Customer/GetById?id=" + id);
    }

    function getDocumentByProject(projectId) {
        return AppHttp.get(apiUrl + "projectdocument/getbyproject?projectId=" + projectId);
    }
    function delDocument(data) {
        return AppHttp.delete(apiUrl + "projectdocument", data);
    }

    function getNoteByProject(projectId) {
        return AppHttp.get(apiUrl + "Note/GetByProjectId?projectId=" + projectId);
    }

    function getRecentNoteByProject(projectId, top) {
        return AppHttp.get(apiUrl + "Note/GetRecentNotes?projectId=" + projectId + "&topRecord=" + top);
    }

    function delNote(data) {
        return AppHttp.delete(apiUrl + "Note", data);
    }

    function validProject(obj) {
        var message = [];
        if (obj.project.name == undefined || obj.project.name == null || obj.project.name.length < 1) {
            message.push($filter("translate")("projectname_invalid"));
        }

        if (obj.customer.name == undefined || obj.customer.name == null || obj.customer.name.length < 1) {
            message.push($filter("translate")("Message_CustomerName_Invalid"));
        }

        if (obj.project.reference == undefined || obj.project.reference == null) {
            message.push($filter("translate")("reference_invalid"));
            return message;
        }

        return message;
    }

    function validDocument(obj) {

        var message = [];
        if (obj.fileContent == undefined || !obj.fileContent) {
            message.push($filter("translate")("Message_FileContent_invalid"));
        }

        if (obj.projectId == undefined || obj.projectId < 1) {
            message.push($filter("translate")("Message_ProjectId_invalid"));
        }

        return message;
    }
    function validNote(obj) {

        var message = [];
        if (obj.title == undefined || !obj.title) {
            message.push($filter("translate")("Message_NoteTitle_invalid"));
        }
        if (obj.description == undefined || !obj.description) {
            message.push($filter("translate")("Message_Description_invalid"));
        }

        if (obj.projectId == undefined || obj.projectId < 1) {
            message.push($filter("translate")("Message_ProjectId_invalid"));
        }

        return message;
    }

}

function Account(AppHttp, $http, $filter) {
    var service = {
        addUser: addUser,
        updateUserInformation: updateUserInformation,
        sendMailResetPassword: sendMailResetPassword,
        sendMailInviteEmployee: sendMailInviteEmployee,
        login: login,
        resetPassword: resetPassword,
        validLogin: validLogin,
        validRegister: validRegister,
        validAccountSetup: validAccountSetup,
        validCompanySetup: validCompanySetup,
        validEmployeeSetup: validEmployeeSetup,
        getListInvite: getListInvite,
        validFullname: validFullname,
        validEmail: validEmail,
        validUser: validUser,
        validChangePass: validChangePass,
        getUserList: getUserList,
        updateAccountExpirationDate: updateAccountExpirationDate,
        valiPassword: valiPassword,
        getUserInfor: getUserInfor,
        getCurrentUserInformation: getCurrentUserInformation,
        forgotPassword: forgotPassword,
        changePassword: changePassword
    };

    return service;
    function login(data) {
        return AppHttp.post(apiUrl + "oauth/Login", data);
    }

    function forgotPassword(data) {
        return AppHttp.post(apiUrl + "oauth/ForgotPassword", data);
    }

    function sendMailResetPassword(data) {
        return AppHttp.post(apiUrl + "user/sendMailResetPassword", data);
    }

    function addUser(data) {
        return AppHttp.post(apiUrl + "user/AddUser", data);
    }

    function updateUserInformation(data) {
        return AppHttp.put(apiUrl + "user/UpdateUserInformation", data);
    }

    function updateAccountExpirationDate(data) {
        return AppHttp.put(apiUrl + "user/UpdateAccountExpirationDate", data);
    }


    function getUserList() {
        return AppHttp.get(apiUrl + "user/GetUserList");
    }

    function getUserInfor(userName) {
        return AppHttp.get(apiUrl + "user/GetUserInformation?userName=" + userName);
    }
    function getCurrentUserInformation() {
        return AppHttp.get(apiUrl + "user/GetCurrentUserInformation");
    }
    function sendMailInviteEmployee(data) {
        return AppHttp.post(apiUrl + "user/sendMailInviteEmployee", data);
    }

    function resetPassword(data) {
        return AppHttp.post(apiUrl + "user/resetPassword", data);
    }
    function changePassword(data) {
        return AppHttp.put(apiUrl + "user/UpdateUserPassword", data);
    }

    function validCompanySetup(obj) {

    }

    function validEmployeeSetup(obj) {
        var valid = true;
        angular.forEach(obj, function (emp, k) {
            if ((emp.fullname && !emp.email) || (!emp.fullname && emp.email.length)) {
                valid = false;
            } else {
                if (emp.fullname && emp.email) {
                    if (!validEmail() || !validFullname())
                        valid = false;
                }
            }
        });
        return valid;
    }

    function validEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function valiPassword(pass) {
        var patt = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        var res = patt.test(pass);
        console.log(res);
        return res;
    }

    function validChangePass(obj) {
        var message = [];
        if (obj.OldPassword.length < 1) {
            message.push($filter("translate")("account_password_invalid"));
            return message;
        }
        if (obj.NewPassword.length < 1) {
            message.push($filter("translate")("account_old_password_invalid"));
            return message;
        }
        if (obj.ConfirmPassword.length < 1) {
            message.push($filter("translate")("account_confirm_password_invalid"));
            return message;
        }

        if (obj.NewPassword != obj.ConfirmPassword) {
            message.push($filter("translate")("UsersManagement_Confirm_Password_Not_Match"));
            return message;
        }
        if (((!valiPassword(obj.NewPassword) && obj.NewPassword.length > 0) || (!valiPassword(obj.ConfirmPassword) && obj.ConfirmPassword.length > 0))) {
            message.push($filter("translate")("The_password_is_at_least_12_characters_long"));
            message.push($filter("translate")("The_password_is_alphanumeric_and_contain_both_letters_and_numbers"));
            message.push($filter("translate")("The_password_is_a_mix_of_uppercase_and_lowercase_letters"));
            message.push($filter("translate")("The_password_contains_special_characters_"));
            return message;
        }
        return message;
    }

    function getListInvite(obj) {
        var list = [];
        angular.forEach(obj, function (emp, k) {
            if (emp.fullname.length > 0 && emp.email.length > 0) {
                if (validFullname(emp.fullname) && validEmail(emp.email)) {
                    var inv = {},
                    fname = emp.fullname.split(" ");
                    inv.first_name = fname[0];
                    fname.splice(0, 1);
                    inv.last_name = fname.join(" ");
                    inv.email = emp.email;
                    inv.group = emp.group;
                    inv.role_id = emp.role;
                    inv.dep = emp.dep;
                    list.push(inv);
                }
            }
        });
        return list;
    }

    function validFullname(fullname) {
        var name = fullname.split(" ");
        if (name.length < 2) {
            return false;
        }
        return true;
    }

    function validRegister(obj) {
        var message = [];
        if (!validFullname(obj.full_name)) {
            message.push($filter("translate")("account_fullname_invalid"));
        }
        if (!validEmail(obj.email)) {
            message.push($filter("translate")("account_email_invalid"));
        }
        if (obj.password.length < 1) {
            message.push($filter("translate")("account_password_invalid"));
        }
        if (obj.confirmpass.length < 1) {
            message.push($filter("translate")("account_confirmpass_invalid"));
        }

        if (obj.password != obj.confirmpassword) {
            message.push("Confirm password must match password!");
        }



        return message;
    }

    function validUser(obj) {
        var message = [];
        if (!(obj.UserName)) {
            message.push($filter("translate")("Message_UserName_invalid"));
        }

        if (!(obj.FirstName)) {
            message.push($filter("translate")("Message_FirstName_invalid"));
        }

        if (!(obj.LastName)) {
            message.push($filter("translate")("Message_LastName_invalid"));
        }

        if (obj.UserId == null && (!obj.Password || !valiPassword(obj.Password))) {
            message.push($filter("translate")("Message_Password_invalid"));
            message.push("The password is at least 12 characters long.");
            message.push("The password is alphanumeric and contain both letters and numbers.");
            message.push("The password is a mix of uppercase and lowercase letters.");
            message.push("The password contains special characters such as #,$ etc.");
            //message.push('�	The password should not contain contextual information such as username, the word �Active� or the word �Safety.');
        }
        if(obj.Password == obj.UserName) {
            message.push("Username and password must be different.");
        }

        if (!validEmail(obj.Email)) {
            message.push($filter("translate")("Message_Email_invalid"));
        }

        if (obj.LanguageCultureCode == undefined) {
            message.push($filter("translate")("Message_LanguageCultureCode_invalid"));
        }

        if (obj.UserRoles.length <= 0) {
            message.push($filter("translate")("Message_roleName_invalid"));
        }

        return message;
    }

    function validAccountSetup(obj) {
        var message = [];
        if (obj.company_address.length < 1) {
            message.push($filter("translate")("account_address_invalid"));
        }
        if (obj.city.length < 1) {
            message.push("auth_city_error");
        }
        if (obj.postal_code.length < 1) {
            message.push("auth_postalcode_error");
        }
        if (obj.country.length < 1) {
            message.push("auth_country_error");
        }
        if (obj.time_format.length < 1) {
            message.push("auth_timeformat_error");
        }
        if (obj.date_format.length < 1) {
            message.push("auth_dateformat_error");
        }
        if (obj.timezone.length < 1) {
            message.push("auth_timezone_error");
        }
        if (obj.industrial.length < 1) {
            message.push("auth_industrial_error");
        }
        if (obj.department.length < 1) {
            message.push("auth_department_error");
        }
        return message;
    }

    function validLogin(obj) {
        var message = [];
        if (obj.Password.length < 1) {
            message.push($filter("translate")("account_password_invalid"));
        }
        if (obj.UserName.length < 1) {
            message.push($filter("translate")("account_email_invalid"));
        }
        return message;
    }
}