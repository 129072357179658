'use strict';
var resizeFlag = true;
//var apiUrl = 'https://esdsapi.azurewebsites.net/api/';// api for pilot
//var apiUrl = 'https://esdsdemoapi.azurewebsites.net/api/';//api for demo
var apiUrl = 'https://esdsdevapi.azurewebsites.net/api/';// api for scaling 
//var apiUrl = 'http://192.168.1.83:9999/api/';
//var apiUrl = 'http://52.168.55.27:8080/api/';
//var apiUrl = 'http://localhost:51294/api/'; 
//var apiUrl = 'http://52.168.55.27:8081/api/';
 
var oAuthCookieKey = 'OAUTHINFO';
(function ($, sr) {
    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    var debounce = function (func, threshold, execAsap) {
        var timeout;
        return function debounced() {
            var obj = this,
                args = arguments;

            function delayed() { 
                if (!execAsap) func.apply(obj, args);
                timeout = null;
            };
            if (timeout) clearTimeout(timeout);
            else if (execAsap) func.apply(obj, args);
            timeout = setTimeout(delayed, threshold || 100);
        };
    }
    // smartresize 
    jQuery.fn[sr] = function (fn) {
        return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
    };
})(jQuery, 'smartresize');
var addNewSystem = "addNewSystem";
var isBusy = false;
angular.module('app', ['ui.router', 'oc.lazyLoad', 'ngCookies', 'ngSanitize', 'pascalprecht.translate', 'ngAnimate', 'ngSanitize', 'long2know', 'naif.base64', 'ui.bootstrap', 'ui.bootstrap.contextMenu', 'ng-bootstrap-datepicker', 'ngClickCopy', 'pdf', 'kendo.directives', 'ui.select', 'checklist-model', 'angular-intro', 'clickOut', 'ngTouch', 'Chronicle','cfp.hotkeys']).run(AppRun).controller('appController', appController).controller('logOutController', logOutController).controller('permissionController', permissionController);
// function AppRun is call after function in Config
function AppRun($rootScope, AppLoading, $translate, $state, $timeout, $stateParams, $location, AppCookies) {
    $rootScope.AppLoading = AppLoading;
    $rootScope.$state = $state;
    //$translate.use('fr');
    $rootScope.$on('$viewContentLoaded', function () {
        $timeout(function () {
            $(".nav-item.section-item.open .sub-menu").show();
            $('.nav-item.section-item.open .glyphicon-chevron-up').addClass('glyphicon-chevron-down');
            $('.nav-item.section-item.open .glyphicon-chevron-up').removeClass('glyphicon-chevron-up');
        }, 50);
    });
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
        // redirect to login page if not logged in and trying to access a restricted page
        //var restrictedPage = $.inArray($location.path(), ['/auth/login', '/auth/register', '/auth/hello']) === -1;
        var loggedIn = AppCookies.get('user');
        if (!loggedIn && toState.data != null && !toState.data.allowGuest) {
            $location.path('auth/login');
            window.location.href = $location.$$absUrl;
        }
        var pageUrl = $location.absUrl();
        if (pageUrl.lastIndexOf('/settings/translate') >= 0) {
            $rootScope.isShowButtonHelp = false;
        }
        if (pageUrl.lastIndexOf('/projects/reviewsystem') >= 0) {
            $rootScope.isShowButtonHelp = false;
        }
    });
}

function appController() { }

function logOutController($scope, $state, AppCookies, Account, $uibModal, AppLoading) {
    $scope.onLogout = function () {
        AppCookies.delete('user');
        $state.go('auth.login');
    };
}

function permissionController(Common) {
    Common.checkUserRole();
}